import { useState, useEffect } from 'react';
import React, { Component } from 'react';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import AuthUser from './AuthUser';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
const Toast = Swal.mixin({
    position: 'top-end',
    timer: 2000,
    showConfirmButton: false,
    toast: true,
})

const Istanze = () => {
    const aprimodal = () => {
        document.getElementById('nuovoavviso').style.display = "inline";
    }
    const http = AuthUser().http;
    const user = AuthUser().user;
    const [avvisi, setavvsis] = useState(null);
    const [updatepage, setupdatepage] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        console.log(user);
        http.post('/table/istanzes/getfilter', user.comune == 'all' ? { where: 'identificativo=' + location.state.identificativo } :
            { where: 'identificativo= \'' + location.state.identificativo + '\' and ( JSON_EXTRACT(istanzajson, \'$.comunedistretto\') like \'%' + user.comune + '%\' or JSON_EXTRACT(istanzajson, \'$.comunebeneficiario\') like \'%' + user.comune + '%\' ) ' }).then(response => {
                // @ts-ignore 
                setavvsis(response.data.result);
                console.log('State', location.state.identificativo);
            });
    }, [updatepage]);

    return (
        <>
            <h3>Istanze</h3>
            <div className="card-body">
                <table id="tablemenu" className="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>Num°</th>
                            <th>Id Domanda</th>
                            <th>Data Invio</th>
                            <th>Domanda</th>
                            <th>Beneficiario</th>
                            <th>residenza</th>
                            <th>iban</th>
                            <th>Azioni</th>
                        </tr>
                    </thead>
                    <tbody>
                        {avvisi && avvisi.map((m: any, i: any) => {

                            let jsondati = JSON.parse(m.istanzajson);


                            return (
                                <tr key={m.id}>
                                    <td>{(i + 1)}</td>
                                    <td>{m.id}</td>
                                    <td>{m.created_at}</td>
                                    <td>{jsondati.nome} {jsondati.cognome}</td>
                                    <td>{jsondati.nomeminore} {jsondati.cognomeminore}</td>
                                    <td>{jsondati.indirizzoresidenza} {jsondati.civicoresidenza}</td>
                                    <td>{jsondati.iban}</td>
                                    <td>
                                        <a target='_blank' className="elementor-button-link elementor-button elementor-size-sm" onClick={() => navigate('/istanza', { state: { identificativo: m.identificativo, datainvio: m.created_at, json: jsondati, id: m.id, note: m.note, esito: m.esito } })} role="button">Apri</a>
                                    </td>
                                </tr>
                            )

                        })}
                    </tbody>
                </table>
            </div>
        </>
    )
}
export default Istanze;